import crudActions from "@/store/templates/crud/actions";
import api from "@/api";
import { FETCH, SAVE } from "../../templates/crud/action-types";
import { SET_IS_LOADING, SET_MODEL } from "../../templates/crud/mutation-types";
import { isNil } from "lodash";
import store from "@/store";

export default {
  async finalize({ commit, dispatch, rootGetters }, creditNoteId) {
    try {
      const invoiceId = rootGetters["invoice/id"];
      const { entities, result } = await api.creditNote.finalize(
        creditNoteId,
        invoiceId
      );
      commit(SET_MODEL, { entities, id: result });
    } catch (e) {
      const message =
        !isNil(e.response?.data?.error) &&
        !isNil(e.response?.data?.error?.detail)
          ? e.response?.data?.error?.detail
          : e.response?.data?.message;
      dispatch(
        "snackbar/addItem",
        {
          text: message,
          color: "warning"
        },
        { root: true }
      );
    }
  },
  ...crudActions(api.creditNote, "credit note"),
  async [SAVE]({ state, commit, dispatch }, invoiceId) {
    try {
      commit(SET_IS_LOADING, true);
      const isCreate = isNil(state.model.id);
      const { entities, result } = isCreate
        ? await api.creditNote.create(state.model, invoiceId)
        : await api.creditNote.update(state.model.id, state.model);
      dispatch(`invoice/${FETCH}`, { id: invoiceId }, { root: true });
      commit(SET_MODEL, { entities, id: result });
      commit(SET_IS_LOADING, false);

      dispatch(
        "snackbar/addCrudSuccess",
        { entityName: "credit note", isCreate },
        { root: true }
      );

      return result;
    } catch (e) {
      const message =
        !isNil(e.response?.data?.error) &&
        !isNil(e.response?.data?.error?.detail)
          ? e.response?.data?.error?.detail
          : e.response?.data?.message;
      dispatch(
        "snackbar/addItem",
        {
          text: message,
          color: "warning"
        },
        { root: true }
      );
      throw e;
    }
  },
  async [FETCH]({ commit, rootGetters }, { id }) {
    try {
      window.Echo.connector.pusher.config.auth.headers["Authorization"] =
        "Bearer " + store.getters["auth/accessToken"];
      window.Echo.private(`CreditNote.${id}`).listen(
        ".credit-note.pdf.generated",
        e => {
          console.log(e);
          commit("setPdfDownloadable");
        }
      );
      const invoiceId = rootGetters["invoice/id"];
      commit(SET_IS_LOADING, true);
      const { entities, result } = await api.creditNote.fetch(invoiceId, id);
      commit(SET_MODEL, { entities, id: result });
      commit(SET_IS_LOADING, false);
      return result;
    } catch (e) {
      console.error("Error with CRUD module fetch action:", e);
    }
  }
};
